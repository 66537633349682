

.tab {

  @media (max-width: 575px) {
    .tabs {
      margin-bottom: 20px;
    }
  }
  @media (min-width: 576px) and(max-width: 767px) {

  }
  @media (min-width: 767px) and(max-width: 991px) {

  }
  @media (min-width: 992px) and(max-width: 1200px) {

  }
}