@import "../../global/variable";


.welcome{
  .show-welcome{
    .user-info {

      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      padding:46px 0 10px 0;
      text-align: center;
      border-radius: 6px;
      margin-bottom: 40px;


      span {
        display: block;
      }
    }
    .user-name {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 100px;
      width: 80px;
      margin: auto;
      text-align: center;
      color: $red;
      font-size: 22px;
      padding: 22px 0;
      font-family: 'IRANSansMedium', sans-serif;
    }
    .welcome {
      font-size: 22px;
      color: $white;
      font-family: 'IRANSansMedium', sans-serif;
      line-height: 42px;
    }
    .describe {
      font-size: 14px;
      color: $black;
      width: 75%;
      text-align: justify;
      margin: auto;

    }
  }

  input[type=text] ,textarea{
    margin: 10px 0;
  }
  .error {
    background: $errorUpload !important;
    border-color: $errorUpload !important;
  }

  .type-error {
    color: $errorColor;
    font-size: 12px;
    display: block;
    position: relative;
    top: -7px;right: 38px;
  }
  input[type=file] {
    outline: none !important;
    color: transparent;
    opacity: 0;
    position: absolute;
    background: #F2F3F5;
    margin-right: -222px;
    margin-top: 6px;
  }
  .upload-box{
    margin-top: 20px;    margin-bottom: 50px;
    .btn {
      padding: 4px 40px 4px 31px;
      width: max-content;
      border-radius: 6px;
      color: $white;
      outline: none;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      font-family: "IRANSansMedium", sans-serif;
      display: inline;

      &.blue{
        border: 1px solid $crimson;
        background: #A7AEB5;
        color: $white;
        background: $crimson;
      }
      .icon-upload-white {
        &:before {
          margin-right: -26px;
          margin-top: 7px;
        }
      }
      &.uploading {
        border: 1px solid $inProcessText;
        background: $inProcessText;
        .icon-load {
          &:before {
            margin-right: -33px;
            margin-top: 5px;
          }
        }
      }


      &.success {
        border: 1px solid $doneText;
        background: $doneText;

        .icon-close-upload {
          &:before {
            margin-right: -26px;
            margin-top: 11px;
          }
        }
      }

    }
  }
  .action{
    text-align: left;
    margin: 20px 0;
  }
  .heading-notify{
    color: $thColor ; font-size: $fontLarge;     font-family: "IRANSansMedium", sans-serif;

  }


}