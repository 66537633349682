@import "../global/variable";


/**------------------
MediaQuerie style for my check list
-------------------- */

  @media (max-width: 575px) {
  }

  @media (min-width: 992px) and(max-width: 1199px) {

  }
  @media (min-width: 768px) and(max-width: 991px) {

  }


  @media (max-width: 767px) {
    .show-project{
      .percent{
        margin-bottom: 25px;
      }
    }
  }

  @media (min-width: 992px) {


  }

  @media (min-width: 1200px) {
    .show-project{
      .right.right{
        border-left: 1px solid $darkGray;
      }
    }
  }



