@import "./variable";
.vue-popover{
  width: max-content !important;
  background-color: transparent !important;
  box-shadow: unset!important;
  margin-top: 20px;

  &:before{
    content: unset !important;
  }
  .info-profile {
    background-color: #ffff;
    width: 69px;
    z-index: 1;
    padding: 10px;
    margin: -64px auto;
    position: relative;
    box-shadow: 0 4px 8px 0 rgb(19 37 71 / 10%);
    p {
      color: #949393;
      font-size: $fontSmall;
      cursor: pointer;
    }
  }
  .status-box{
    background-color: #fff;
    width: 110px;
    z-index: 1;
    padding: 10px;
    margin: auto;
    position: relative;
    right: 55px;
    top: -20px;
    box-shadow: 0 4px 8px 0 rgb(19 37 71 / 10%);
    p{
      margin-right: 22px;
      color: #949393;
      font-size: $fontSmall;
      cursor: pointer;
    }
    .pending {
      .icon-status {
        &:before {
          background-image: url("../../image/icons/status/pending.svg");
        }
      }
    }
    .done {
      border-color: $doneText;
      .icon-status {
        &:before {
          background-image: url("../../image/icons/status/done.svg");
        }
      }
    }
    .in-process {
      .icon-status {
        &:before {
          background-image: url("../../image/icons/status/inProcess.svg");
        }
      }
    }
    .icon-status {
      &:before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        background-repeat: no-repeat;
        margin-top:5px;
        margin-right: -24px;
        vertical-align: middle;
      }
    }  }
}
.trigger{
  display: block !important;
}
