@import "../../global/variable";

.basic-info{
  .close{
    &:before{
      margin-top: 7px;
      margin-right: 20px;
    }
  }
  .selected{
    margin-bottom: 12px;
  }
   .gray {
    display: inline-block;
    background: #A7AEB5;
    width: 38px;
    height: 38px;
    border-radius: 5px;
    vertical-align: middle;
    position: relative;
    top: 36px;
    border: none;
     .icon-add{
       margin-top: 7px;
       margin-right: 6px;
     }
  }
  .department-part{
    margin: 25px 0;
  }
  .department-info{

      padding: 5px 0;
    .icon{
      margin-right: 32px;
    }
    .name{
      color: $thColor;
      font-size: $fontSmall;
      font-family: 'IRANSansMedium', sans-serif;

    }
    .owner-avatar{
        width: 32px;    margin-left: 20px;
        height: 32px;
        border-radius: 50px;
        vertical-align: middle;

    }
  }
}