@import "../global/variable";


/**------------------
MediaQuerie style for my check list
-------------------- */
.my-check-list-page{
  .left-row {
    border-top: 1px solid #DDDDDD;
    padding: 20px;
    margin-top: 20px;
  }
  @media (max-width: 575px) {}

  @media (min-width: 992px) and(max-width: 1199px) {
    .detail-project {
      .white-btn {
        padding: 8px 15px 8px 50px;
        width: 200px;
        margin-bottom: 20px;
      }
    }
  }
  @media (min-width: 768px) and(max-width: 991px) {
    .detail-project{
      .white-btn{
        padding: 8px 15px 8px 8px ;
        width: 100%;font-size: 14px;

      }
    }
  }


  @media (max-width: 767px) {
    .detail-project {
      .white-btn {
        padding: 8px 15px 8px 50px;
        width: 200px;
        margin-bottom: 20px;
      }
    }
    .left-data{
      text-align: left;
    }
  }

  @media (min-width: 992px) {

    .left-data{
      text-align: left;
    }
    .left-row {
      border-right: 1px solid $darkGray;
      margin-top: unset;border-top: none;
    }
  }

  @media (min-width: 1200px) {
    .detail-project{
      .white-btn{
        padding: 8px 15px 8px 8px;
        width: 100%;

      }
    }
    .description {
      font-size: $fontLarge;
    }
  }

}

