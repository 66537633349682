$default-color: #00B894;
$success-color: #00B894;
$warning-color: #e6a23c;
$error-color: #f56c6c;
.percent{
  p{
    margin-bottom: -14px;
  }
  p:nth-child(1){
    .k-progress-outer-line{
      background-image: url("../../image/Striped.svg") !important;
    }
  }
  p:nth-child(2){
    .k-progress-outer-line{
      background-image: url("../../image/Striped2.svg") !important;
    }
  }
}
.k-progress-outer {
  height: 10px !important;
  width: 284px !important;
  margin-right: unset !important;
  border-radius: 7px !important;
  background: rgba(115, 103, 240, 0.12) !important;
}
.k-progress-text {
  font-size: 7px !important;
  display: inline !important;
  top: -6px !important;
  right: 148px !important;
  width: max-content !important;
  color: #fff !important;


}


.error-info{
  .k-progress-outer-line{
    border-radius: 4px;
    background-image: linear-gradient(270deg, #FF9300 0%, #E17055 100%);
  }

}


.k-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #606266;
  font-size: 14px;
  margin-right: 8px;
  display: -webkit-box;
  display: block;
  -webkit-box-align: center;
  align-items: center;


  .k-progress-outer-line{
    float: left;
    height: 10px !important;
    position: relative;
    mix-blend-mode: normal;
    border-radius: 100px;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
    background-size: cover;
    background-color: transparent  !important;

  }
  &-outer {
    display: block;
    vertical-align: middle;
    box-sizing: border-box;
    margin-right: -55px;
    height: 6px;
    width: 114px;
    border-radius: 4px;
    background-color: #E0E4E7;
    float: left;
    &-bg {
      width: 100%;
      position: relative;
      &-border {
        border-radius: 100px;
      }
    }
    &-line {
      position: relative;
      background: $default-color;
      border-radius: 100px;
      transition: all .4s cubic-bezier(.08, .82, .17, 1) 0s;
      &-success {
        background: $success-color;

      }
      &-warning {
        background: $warning-color;
      }
      &-error {
        background: $error-color;
      }
      &-active {
        background: #fff;
        height: inherit;
        border-radius: 10px;
        opacity: 0;
        animation: kp-active 2.4s cubic-bezier(0, 0, 0.2, 1) infinite;
        content: '';
      }
    }
    &-cut {
      position: relative;
      display: flex;
    }
  }

  &-text {
    display:none;
    vertical-align: middle;
    font-family: IRANSansFaNum !important;
    word-break: keep-all;
    position: relative;
    top: -5px;
    height: 17px;
    width: 20px;
    right: -44px;
    color: #000000;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: right;
  }
}

@keyframes kp-active {
  0% {
    width: 0;
    opacity: 0.2;
  }
  30% {
    width: 0;
    opacity: 0.6;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

@keyframes kp-flow {
  from { filter: hue-rotate(0deg); }
  to { filter: hue-rotate(360deg); }
}
