@import "../global/variable";
@import "./partials/welcome";
@import "./partials/basicInfo";
@import "./partials/addTask";
@import "./responsive";



.setting-page {

  .gray{
    display: inline-block;
    background: #A7AEB5;
    width: 38px;
    height: 38px;
    border-radius: 5px;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    .icon-add{
      top: 0 !important;
      right: 3px;
    }
  }
  .right-col {
    width: 38%;
    display: inline-block;
    text-align: right;
  }

  .color-title {
    margin: 20px 0;
  }

  .left-col {
    width: 60%;
    display: inline-block;
    text-align: left;
    .icon{
      margin: 0 16px;
      position: relative;
      top: 8px;
    }
  }

  .text {
    width: 90%;
    text-align: justify;
  }

  .info-profile {
    margin-top: -32px !important;
  }

  .icon-up, .icon-down {
    float: left;

    &:before {
      margin-top: 15px;
    }
  }

  .avatar-load {
    width: 32px;
    height: 32px;
  }

  .single {
    padding: 12px 4px;width: 92%;
  }

  .single:nth-child(odd) {
    background: $bgInActive;
  }

  .heading-box:nth-child(odd) {
    padding: 10px 0;
  }

  .heading-box {
    .icon-more-gray {
      margin-left: 22px;
    }
  }

  .description {
    font-size: $fontSmall;
    color: $grayBtn;
    margin-right: 34px;
    margin-top: 14px;
  }

  .left-align {
    float: left;
  }

  .icon-box .icon {
    margin: 0 16px;
  }

  .heading {
    //color: #00CFE8;
    font-size: 16px;
    font-family: 'IRANSansMedium', sans-serif;
  }

  .icon-minimize {
    margin-right: 35px;

    &:before {
      position: absolute;
      border-radius: 2px;
      margin-right: -37px;
      FONT-WEIGHT: 200;
    }
  }

  .task-title {
    color: $buttonBg;
    font-family: 'IRANSansMedium', sans-serif;
    font-size: $fontSmall;
  }

  .icon-check {
    margin-right: 35px;

    &:before {
      position: absolute;
      border-radius: 2px;
      margin-right: -37px;
      margin-top: 3px;
    }
  }
}
@media(max-width:574px){
  .add-task{
    width: 90%;
  }
}
@media(min-width: 575px) and(max-width:767px){
  .add-task{
    width: 450px;
  }
}

@media(min-width: 768px) and(max-width:991px){
  .add-task{
    width: 450px;
  }
}
@media(min-width: 992px) and(max-width:1199px){
  .add-task{
    width: 497px;
  }
}
@media(min-width: 1200px){
  .add-task{
    width: 497px;
  }
}