@import "../global/variable";

.permission-component {
  border: 1px solid $borderInput;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 10px;

  .action{
    max-height: 220px;
    overflow-y: scroll;
    min-height: 220px;
  }
  .title{
    font-size: 13px;
    color: #80868a;
    padding-right: 34px;
    &:before{
      content: "";
      position: absolute;
      width: 13px;
      height: 13px;
      background-color: $borderInput;
      margin-right: -20px;
      margin-top: 5px;
      border-radius: 2px;
    }
  }
  p {
    border-bottom: 1px solid $borderInput;
    padding: 10px;
    margin: 0;


    &:last-child {
      border-bottom: none;
    }
  }
}