@font-face {
  font-family: IRANSansMedium;
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/eot/IRANSansWeb_Medium.eot');
  src: url('../../fonts/eot/IRANSansWeb_Medium.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url('../../fonts/woff2/IRANSansWeb_Medium.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url('../../fonts/woff/IRANSansWeb_Medium.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../../fonts/ttf/IRANSansWeb_Medium.ttf') format('truetype');
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/eot/IRANSansWeb.eot');
  src: url('../../fonts/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url('../../fonts/woff2/IRANSansWeb.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url('../../fonts/woff/IRANSansWeb.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../../fonts/ttf/IRANSansWeb.ttf') format('truetype');
}

@font-face {
  font-family: IRANSansFaNum;
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/ttf/IRANSansFaNum.ttf') format('truetype');
}


/**------------------
font-family
-------------------- */

.iran-sans {
  font-family: 'IRANSans', sans-serif;
}
.medium-font {
  font-family: 'IRANSansMedium', sans-serif;
}

.fa-number {
  font-family: 'IRANSansFaNum', sans-serif;
}

@mixin fontBetter {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  @include fontBetter;
}
