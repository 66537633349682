/**--------------------------------------------------------------------------------------------------------------------
status-color-variable
-------------------------------------------------------------------------------------------------------------------- */
$pendingBg: rgba(255, 159, 67, 0.28);
$pendingText: #FF9F43;
$transparent: transparent;
$doneText: #28C76F;
$inProcessText: #00CFE8;

/**--------------------------------------------------------------------------------------------------------------------
global-color-variable
-------------------------------------------------------------------------------------------------------------------- */
$mainBgSidebar: #4A4A4A;
$mainBgTobBar: #1D207A;
$white: #ffff;
$mainBg: #F3F2F7;
$bodyBg: #E5E5E5;
$crimson: #393E7A;
$slideBg: #F8F8F8;
$label: #80868a;
$black: #000000;
$buttonBg: #7367F0;
$errorColor: #FF0000;

$red: #FF0000;
$grayBtn: #A7AEB5;

$gray: #979797;
$smokedColor: #EBE9F1;
$blue: #357FEF;
$orange: #FF9F43;
$lightGray: #7B7B7B;
$darkGray: #DDDDDD;
$secondaryColor: #FFB300;
$thColor: #717171;
$textTd: #5E5873;
$textJobTitle: #B9B9C3;
$darkgrey: #636E72;
$mainColor: #2D3436;
$bgToggle: #DEE2E6;
$bgInActive: #F9F9F9;
$borderInput: #D8D6DE;
$errorUpload: #EA5455;

/**--------------------------------------------------------------------------------------------------------------------
global-fontSize-variable#EA5455
-------------------------------------------------------------------------------------------------------------------- */
$fontSmall: 12px;
$fontNormal: 14px;
$fontLarge: 16px;
$fontHeading: 18px;


.lightGray {
  color: $lightGray;
}