@import "./navigationBar";
@import "./topBar";
@import "./responsive";
$from: 0;
$to: 100;
/**------------------
Padding
-------------------- */
@for $i from $from through $to {
  .p-#{$i} {
    padding: 0px + $i !important;
  }
}

.layout-box{
  background:$mainBg;
}