@import "../../global/variable";
.show-project{
  padding: 10px 21px;

 table{
   width: 94%;
   margin-right: 35px;
 }
  .heading{
    color: #00CFE8;
    font-size: 16px;
    font-family: 'IRANSansMedium', sans-serif;
  }

  .icon-minimize{
    margin-right: 35px;

    &:before{
      position: absolute;
      border-radius: 2px;
      margin-right: -37px;
      FONT-WEIGHT: 200;
    }
  }
  .border-bottom{
    border-bottom: 1px solid $darkGray;    padding-bottom: 25px;
  }
  .title{
    color: $crimson;font-family: 'IRANSansMedium', sans-serif;font-size: 16px;
  }

  .k-progress-outer{
    width: 200px !important;
  }
  .first{
    margin-bottom: 12px;
  }
  .user-name {
    border-radius: 100px;
    width: 60px;
    text-align: center;
    color: $red;
    font-size: 22px;
    padding: 12px 0;
    font-family: 'IRANSansMedium', sans-serif;
    background: rgba(115, 103, 240, 0.12);
  }
  .user{
    color: #5E5873;    font-size: 18px;    font-family: 'IRANSansMedium', sans-serif;
  }
  .date{
    color: $gray;font-size: $fontSmall;    position: relative;
    top: -10px;
  }
  .left{
    padding: 0 15px;

  }

}

@media (min-width:1200px) {
  .show-project{
    .right{
      min-height: 650px;
    }
  }
}