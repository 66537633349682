@import "./variable";
@import "./fonts";
@import "./form";
@import "./cxlt-vue2-toastr";
@import "./color";
@import "./common";
@import "./modal";
@import "./kprogress";
@import "./tooltip";
@import "./datePicker";
@import "./select-box";
@import "./icons";
@import "./loading";@import "./tabs";

.upload{
  position: relative;
  overflow: hidden

}
