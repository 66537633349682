@import "./init";
@import "./request";
@import "../global/variable";

.auth {
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  .auth-block-content {
    width: 40%;
    height: 600px;
    padding-top: 114px;
  }
  .slide-show {
    width: 60%;
    background-color: $slideBg;

    div {
      width: 630px;
      background-repeat: no-repeat;
      height: 730px;
      background-position: center center;
      background-image: url("../../image/login.svg");
    }
  }
  .button-submit {
    margin-top: 20px;
  }

  .title {
    color: $black;
    font-size: 16px;
    text-align: right;
    font-weight: 900;
    font-family: 'IRANSansMedium', sans-serif;
  }

  .sub-title {
    color: $label;
    position: relative;
    top: -12px;
    margin-bottom: -2px;
    font-size: 14px;
    font-weight: normal;
  }
}


//custom style for lg-device
@media(min-width: 1200px) {
  .auth {
    width: 80%;
    max-width: 1200px;
  }
}

//custom style for md-device
@media(min-width: 992px) and(max-width: 1199px) {
  .auth {
    width: 90%;
    max-width: 992px;
  }
}

//custom style for md-device
@media(max-width: 991px) {
  .auth {
    max-width: 383px;
    width: 90%;

    .auth-block-content {
      width: 80%;
      margin: auto;
      float: none;
    }

    .slide-show {
      display: none;
    }
  }

}