@import "../global/variable";
@import "./partials/add";
@import "./partials/show";
@import "./partials/edit";
@import "./partials/singleTask";
@import "./responsive";

.project-page{
  td:nth-child(1){
    padding: 13px 0 ;
  }
  .search{
    margin-bottom: 30px;
    input{
      width: 50%;
    }
    .icon-search{
      &:before{
        margin-top: 12px;
        margin-right: -30px;
      }
    }
  }
  .filter{
    margin: 0 0 30px 0;
  }
  .first{
    text-align: left;
    margin: 25px 0;
    button{
      margin-right: 15px;
    }
  }
  table{
    .up{
      position: relative;
      top: -8px;
      color: #a8a8a8;
      font-size: 17px;
      display: inline-block;
      right: -4px;

    }
    .down{
      position: relative;
      top: 0;
      color: #a8a8a8;
      font-size: 17px;
      display: inline-block;
      right: -18px;
    }
    .first-col{
      top: -15px;
    }
    .avatar{
      vertical-align: middle;
      position: relative;
      margin-left: 15px;
    }
    .info{
      display: inline-block;
      position: relative;
      top: 5px;
      .full-name{
        color: $textTd;
        display: block;
      }
      .job-title{
        color: $textJobTitle;
        display: block;
        font-size: $fontSmall;

      }
    }

  }
}
@media(max-width: 575px){
  .project-page{
    .first{
      text-align: right;
      button{
        margin-left: 12px;
        margin-bottom: 15px;
        margin-right: unset;
      }
    }
  }
}