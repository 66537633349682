@import "../../global/variable";

.add-task{
  .action-btn{
    text-align: left;
  }
    .close-upload{
      display: inline-block;
      float: left;
      margin-top: 12px;
    }
  .file{
    background: #F3F2F7;
    border-radius: 3px;
    margin-top: 26px;
    padding: 1px 7px;
    height: 36px;
    .name{
      color: #979797;font-size: 14px;
    }
  }



  .type-error {
    color: $errorColor !important;
    font-size: 12px;
    display: block;
    position: relative;
    top: -42px;
  }
  .upload{
    color: #A7AEB5 !important;
    border: 1px solid #A7AEB5;
    width: 100%;
    outline: none;
    cursor: pointer;
    text-align: center;
    line-height: unset !important;
    border-radius: 6px;
    font-size: 14px !important;
    margin-top: 26px;

  }
  .btn {
    width: 100%;
    outline: none;
    cursor: pointer;
    text-align: center;
    line-height: unset !important;
    border-radius: 6px;
    margin-top: 26px;
    &.error {
      background: #EA5455 !important;
      border-color: #EA5455 !important;
      color: #fff !important;
      width: 100%;
      outline: none;
      cursor: pointer;
      text-align: center;
      line-height: unset !important;
      border-radius: 6px;
      margin-top: 26px;
      padding: 1px 0;

      .icon-upload-white {
        &:before {
          margin-top: 7px;
          margin-right: -40px;
        }
      }
    }
    &.success {
      border: 1px solid $doneText;
      background: $doneText;
      color: $white !important;
      .icon{
        margin-left: 30px;
      }

      .icon-close-upload {
        &:before {
          margin-right: 0;
          margin-top: 10px;
        }
      }
    }
    &.uploading {
      border: 1px solid $inProcessText;
      background: $inProcessText;
      color: $white;
      .icon{
        margin-left: 30px;
      }
      .icon-load {
        &:before {
          margin-right: -5px;
          margin-top: 3px;
        }
      }
    }




  }
  input[type=file] {
    font-size: 13px;
    position: relative;
    left: 0;
    top: -48px;
    opacity: 0;
  }
  .header{
    text-align: right;
    width: 60%;
    margin-bottom: -48px;
    p{
      position: relative;
      top: -45px;
    }
  }
  .type-box{
    display: inline-block;margin-left: 10px;
    input[type="checkbox" ] {
      vertical-align: middle;
    }
  }
}