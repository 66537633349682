@import "../global/variable";

.topBar {
  background-color: $mainBgTobBar;
  height: 65px;
  .show-company{
    background: #ffff;
    border-radius: 6px;
    position: absolute;
    width: 189px;
    right: 16px;
    z-index: 999999;
    display: block;
    direction: rtl;
    text-align: right;
    margin-top: -11px;
    a{
      color: unset;
    }
    p {
      font-size: $fontNormal;
      padding: 10px 18px;
      margin: 0;
    }
    p:nth-child(1){
      border-bottom: 1px solid $smokedColor;

    }
  }
  .show-profile {
    background: $white;
    border-radius: 6px;
    position: absolute;
    width: 189px;
    z-index: 1;
    left: 37px;
    display: block;
    direction: rtl;
    text-align: right;
    margin-top: 9px;

    p {
      font-size: $fontNormal;
      padding: 10px 18px;
      margin: 0;
    }
    p:nth-child(1){
      border-bottom: 1px solid $smokedColor;

    }
  }

  div {
    display: inline-block;
  }

  .user {
    font-size: $fontNormal;
    line-height: $fontNormal;
    text-align: right;
    margin-top: 16px;
    margin-left: 30px;

    span:nth-child(1) {
      color: $white;
      display: block;
    }

    span:nth-child(2) {
      color: #B9B9C3;
      display: block;    position: relative;
      top: 5px;
    }
  }

  .logo {
    background-image: url("../../image/logoTopBar.svg");
    width: 98px;
    height: 10px;
    background-size: cover;
    margin: 28px ;
    background-repeat: no-repeat;
  }

  .avatar-load, .avatar {
    margin-top: -7px;
    margin-left: 50px;
  }
}
