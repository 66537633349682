
.icon{
  &:before {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    vertical-align: middle;
    background-position: center center;
  }
  &.icon-arrow-up{
    background-image: url("../../image/icons/arrow-up.svg");
    height: 5px;
    width: 10px;
    background-repeat: no-repeat;
  }
  &.icon-more {
    &:before {
      content: '';
      background-image: url("../../image/icons/more-vertical.svg");
      height: 24px;
      width: 24px;
    }
  }
  &.icon-more-gray {
    &:before {
      content: '';
      background-image: url("../../image/icons/more-gray.svg");
      height: 18px;
      width: 6px;
    }
  }
  &.icon-bell {
    &:before {
      content: '';
      background-image: url("../../image/icons/setting/bell.svg");
      height: 18px;
      width: 18px;
    }
  }
  &.icon-delete{
    &:before {
      content: '';
      background-image: url("../../image/icons/delete.svg");
      height: 24px;
      width: 24px;
    }
  }
  &.icon-profile{
    &:before {
      content: '';
      background-image: url("../../image/icons/profile.svg");
      height: 20px;
      width: 24px;
    }
  }
  &.icon-active-role{
    &:before {
      content: '';
      background-image: url("../../image/icons/active-role.svg");
      width: 30px;
      height: 28px;
    }
  }
  &.icon-trash{
    &:before {
      content: '';
      background-image: url("../../image/icons/trash.svg");
      height: 24px;
      width: 24px;
    }
  }
  &.icon-menu{
    &:before {
      content: '';
      background-image: url("../../image/icons/menu.svg");
      height: 24px;
      width: 24px;
    }
  }
  &.icon-copy{
    &:before {
      content: '';
      background-image: url("../../image/icons/singleTask/copy.svg");
      height: 18px;
      width: 14px;
    }
  }
  &.icon-up{
    &:before {
      content: '';
      background-image: url("../../image/icons/arrow-up.svg");
      height: 6px;
      width: 12px;
    }
  }
  &.icon-down{
    &:before {
      content: '';
      background-image: url("../../image/icons/arrow-down.svg");
      height: 6px;
      width: 12px;
    }
  }
  &.icon-attach-link{
    &:before {
      content: '';
      background-image: url("../../image/icons/singleTask/attach.svg");
      height: 18px;
      width: 18px;
    }
  }
  &.icon-clock{
    &:before {
      content: '';
      background-image: url("../../image/icons/clock.svg");
      height: 18px;
      width: 19px;
    }
  }
  &.icon-edit{
    &:before {
      content: '';
      background-image: url("../../image/icons/edit.svg");
      height: 22px;
      width: 22px;
    }
  }
  &.icon-mark{
    &:before {
      content: '';
      background-image: url("../../image/icons/mark.svg");
      height: 36px;
      width: 36px;
    }
  }
  &.icon-arrow-down{
    background-image: url("../../image/icons/arrow-down.svg");
    height: 5px;
    width: 10px;
    background-repeat: no-repeat;

  }
  &.icon-close{
    background-image: url("../../image/icons/modal/closeModal.svg");
    height: 12px;
    width: 12px;
  }
  &.close{
    &:before{
      content: '';
      background-image: url("../../image/icons/setting/cancel.svg");
      height: 24px;
      width: 24px;
    }
  }
  &.icon-close-upload{
   &:before{
     content: '';
     background-image: url("../../image/icons/closeUpload.svg");
     height: 12px;
     width: 12px;
   }
  }
  &.icon-search{
   &:before{
     content: '';
     background-image: url("../../image/icons/search.svg");
     height: 14px;
     width: 14px;
   }
  }
  &.icon-load{
   &:before{
     content: '';
     background-image: url("../../image/icons/icon-load.svg");
     height: 24px;
     width: 24px;
   }
  }
  &.icon-add{
    &:before {

      content: '';
      background-image: url("../../image/icons/icon-plus.svg");
      height: 12px;
      width: 12px;
    }
  }
  &.icon-upload{
    &:before {
      content: '';
      background-image: url("../../image/icons/icon-upload.svg");
      height: 18px;
      width: 18px;
    }
  }
  &.icon-upload-white{
    &:before {
      content: '';
      background-image: url("../../image/icons/whiteUpload.svg");
      height: 18px;
      width: 18px;
    }
  }
  &.icon-minimize{
    &:before {
      content: '';
      background-image: url("../../image/icons/singleTask/minimize.svg");
      height: 24px;
      width: 24px;
    }
  }
  &.icon-task{
    &:before {
      content: '';
      background-image: url("../../image/icons/task-icon.svg");
      height: 18px;
      width: 23px;
    }
  }
  &.icon-check{
    &:before {
      content: '';
      background-image: url("../../image/icons/check.svg");
      height: 20px;
      width: 18px;
    }
  }
  &.icon-upload-white{
    &:before {
      content: '';
      background-image: url("../../image/icons/singleTask/uploadWhite.svg");
      height: 18px;
      width: 18px;
    }
  }
  &.icon-download{
    &:before {
      content: '';
      background-image: url("../../image/icons/icon-download.svg");
      height: 18px;
      width: 18px;
    }
  }
  &.icon-attach{
    &:before {
      content: '';
      background-image: url("../../image/icons/attach-icon.svg");
      height: 18px;
      width: 18px;
    }
  }

}
