.add-team{
  input[type=text] {
    width: -webkit-fill-available;
  }
  .btn{
    text-align: left;
    button{
      margin-top: 25px;
    }
  }
  .header{
    text-align: right;
    width: 60%;
    margin-bottom: -48px;
   p{
     position: relative;
     top: -45px;
   }

  }

}