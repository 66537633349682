@import "../global/variable";

.sidebar {
  background-color: $mainBgSidebar;
  position: sticky;
  height: 100%;
  transition-timing-function: ease-in;
  -webkit-transition: width 40ms; /* Safari */
  transition: width 1s;
  transition-duration: 250ms;
  overflow-y: scroll;
  overflow-x: hidden;

  .close-aside {
    display: none;
  }
}
.toggle-mobile {
  .close-aside {
    text-align: center;

    p {
      height: 4px;
      cursor: pointer;
    }
  }
}

/**------------------
 style for sidebar
-------------------- */
//@media (max-width: 575px) {}
//@media (min-width: 576px) and(max-width: 767px) {}
//@media (min-width: 767px) and(max-width: 991px) {}


@media (max-width: 991px) {
  .logo{
    display: none   !important;
  }
  .icon-menu{
    &:before{
      margin-right: 25px;
    }
  }
  .layout-box {
    padding: 20px;
  }
  .panel-container{
    p,span,div{
      font-size: $fontNormal !important;
    }
  }
  .sidebar {
    display: none;
  }
  .toggle-mobile {
    width: 112px;
    background-color: $mainBgSidebar;
    position: fixed;
    height: 100%;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    transition: all 66ms ease-in-out;
  }
}

@media (min-width: 992px) and(max-width: 1199px) {
  .panel-container {
    max-width: 850px;
  }
}

@media (min-width: 992px) {
  .toggle-icon{
    display: none !important;
  }
  .toggle-mobile {
    background-color: $mainBgSidebar;
    position: sticky;
    height: 100%;
    transition-timing-function: ease-in;
    -webkit-transition: width 40ms; /* Safari */
    transition: width 1s;
    transition-duration: 250ms;
    overflow-y: scroll;
    overflow-x: hidden;

    .close-aside {
      display: none;
    }
  }

}


@media (min-width: 1200px) and (max-width: 1450px) {
  .panel-container {
    max-width: 1100px;
  }
}
@media (min-width: 1451px) {
  .panel-container {
    max-width: 1280px;
  }
}


/**------------------
style for sidebar
-------------------- */