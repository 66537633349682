@import "../global/variable";

.aside {
z-index: 9999;

  .navigation-bar{
    right: 0;width: 112px;
    height: 100%;position: sticky;min-height: 110vh;
  }
  ul{
    padding: 0;
    transition: 0s !important;
    margin: 0 auto;
    list-style: none;
    text-align: center;
    li{
      padding: 15px 0;
    }
    .menu-icon{
      display: block;
      margin: auto;
    }
    .myWork-icon{
      background-image: url("../../image/icons/userCheck.svg");
      width: 24px;
      height: 24px;
    }
    .task-icon{
      background-image: url("../../image/icons/tasks.svg");
      width: 24px;
      height: 20px;
    }
    .team-icon{
      background-image: url("../../image/icons/team.svg");
      width: 26px;
      height: 19px;
    }
    .setting-icon{
      background-image: url("../../image/icons/setting.svg");
      width: 28px;
      height: 28px;
    }
    .report-icon{
      background-image: url("../../image/icons/report.svg");
      width: 16px;
      height: 20px;
    }
    .menu-title{
      color: $white;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }

}
