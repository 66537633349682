@import "../global/variable";

form {
  line-height: 36px;
}

/**--------------------------------------------------------------------------------------------------------------------
button global style
------------------------------------------------------------------------------------------------------------------ */

button {
  width: max-content;
  border-radius: 6px;
  border: 1px solid;
  outline: none;
  cursor: pointer;
  text-align: center;
  font-size: $fontNormal;
  font-family: 'IRANSansMedium', sans-serif;

  &.secondary-btn {
    color: $white;
    background: $secondaryColor;
    border-color: $secondaryColor;
    padding: 6px 38px;

  }

  &.blue {
    color: $white;
    background: $crimson;
    border-color: $crimson;
    padding: 6px 14px;
  }
  &.white-btn{
    color: $crimson;
    background: transparent;
    border-color: $crimson;
    padding: 6px 14px;
    .icon{
      margin-left: 34px;
      &:before {
        margin-top: 4px;
      }
    }
  }
  &.gray-btn{
    color: $white;
    border-color: $grayBtn;
    padding:4px 14px;
    background: $grayBtn;
    .icon{
      margin-left: 34px;
      &:before {
        margin-top: 4px;
      }
    }
  }

  .icon-add {
    margin-left: 24px;
    &:before {
      margin-top: 7px;
    }
  }

  &.auth-btn {
    background: $buttonBg;
    width: 100%;
    color: $white;
    padding: 5px 15px;

  }
}

/**--------------------------------------------------------------------------------------------------------------------
table global style
-------------------------------------------------------------------------------------------------------------------- */
.table-box{
  overflow-x: auto;    overflow-y: hidden;
}
table {
  .avatar{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }
  td {
    a {
      color: #2D3436 !important;
    }
  }

  tbody {
    tr:last-child {
      td {
        border-bottom: none;
        white-space: nowrap ;
      }

      th {
        white-space: nowrap ;
        font-weight: unset !important;
      }
    }
  }

  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: auto;


  .title-row {
    height: 65px;
  }

  th {
    white-space: nowrap;
    font-weight: unset;
    font-family: 'IRANSansMedium', sans-serif !important;
    font-size: $fontNormal;
    color: $thColor;
    text-align: right;
    padding:0 5px;
  }

  td {
    white-space: nowrap;
    font-size: $fontNormal;
    color: #2D3436;
    padding: 13px 25px ;
  }
}

/**--------------------------------------------------------------------------------------------------------------------
input global style
---------------------------------------------------------------- */
input[type=text] {
  width: 94%;
  border-radius: 4px;
  color: $label;
  border: 1px solid $borderInput;
  font-family: 'IRANSans', sans-serif;
  font-size: 14px;
  height: 35px;
  padding: 0 8px;
}
textarea{
  width: 94%;
  padding:7px 8px;
  border-radius: 4px;
  color: $label;
  border: 1px solid $borderInput;
  font-family: 'IRANSans', sans-serif;
}
.vs__selected {
  color: $thColor;
  padding: 0 !important;

}
input::placeholder {
  font-size: 12px;
  color: $textJobTitle !important;
  text-align: right;
  position: relative;top: -2px;
  font-family: 'IRANSans', sans-serif;
}
.read-only-input {
  outline: none !important;
}

label {
  font-size: 13px;
  color: $label;
}


.text-error {
  color: $errorColor;
  font-size: 12px;
  display: block;
  margin-bottom: -10px;
  position: relative;
  top: -2px;
}

.input-error {
  border-color: $errorColor !important;
  .vs__dropdown-toggle{
    border-color: $errorColor !important;
  }
}
