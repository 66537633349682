@import "../../global/variable";
.add-user {
  .new-task{
    border: 1px dashed $borderInput;margin: 17px 0 5px;
   padding: 1px 5px;    border-radius: 4px;
    .right-align{
      color: $gray;font-size: $fontSmall;
    }
    .icon-delete{
      margin-left: 20px;
      &:before {
        margin-top: 5px;
      }
    }
  }
  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
  .action-btn {
    text-align: left;
    margin: 20px 0 0;
  }
  .add-modal {
    width: 558px;
    max-width: 558px;
  }

  form {
    width: 100%;
  }

  input {
    color: $crimson;
  }

  .upload-img {
    text-align: center;
  }

  .divider {
    margin: 30px 0 2px;
  }

  .menu-item {
    list-style: none;
    padding: 0;
    text-align: center;

    li {
      font-size: $fontNormal;
      color: $lightGray;
      width: 30%;
      display: inline-block;
      font-family: 'IRANSansMedium', sans-serif;

      &.is-active {
        border-bottom: 3px solid $secondaryColor;

      }
    }
  }

  .first-step {


  }

  .second {
    .add-task{
      .type-error{
        top: unset;
      }
      p{
        margin-top: 36px;

      }
    }
    .delete{
      background-repeat: no-repeat;
      background-image: url('../../../image/icons/delete.svg');
      height: 24px;
      width: 24px;
      top: 6px;
      position: relative;
      float: left;
    }
    .box{
      margin-top: 15px;
      max-height: 200px;
      overflow-x: auto;
      overflow-y: scroll;
    }
    input[type=text] {
      width: 97%;
    }

    .gray {
      display: inline-block;
      background: $grayBtn;
      width: 38px;
      height: 38px;
      border-radius: 5px;
      vertical-align: middle;
      position: relative;
      top: 36px;
      border: none;

      .icon-add {
        margin-right: 7px;
      }
    }

    .upload {
      button {
        margin-top: 36px;
        margin-right: 10px;
      }
    }

    .link {
      input {
        margin-top: 36px;
      }
    }

    .vs--multiple.vs--searchable {
      .vs__selected {
        background-color: $crimson !important;
        color: $white !important;
        margin: 0 0 0 5px !important;
      }
    }

    .vs__deselect {
      svg {
        fill: $white;
        margin-right: 7px;
        margin-top: -2px;
      }
    }
  }
}
@media(max-width: 575px){
  .add-user{
    .menu-item li {
      font-size: 12px;
    }
    .add-modal{
      width: 80%;
      max-width: 80%;
    }
  }

}
