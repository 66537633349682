@import "./variable";

body {
  font-family: 'IRANSans', sans-serif;
  background-color: $bodyBg;
  overflow-x: hidden;margin:0;

}
.panel-container{
  /* Designing for scroll-bar */
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: gainsboro;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.tabs{
  ul{
    margin: 0;padding: 0;
  }
}
.vs--single .vs__selected{
  white-space: nowrap;
}
.empty-data{
  background-color: $bgInActive;
  td{
    text-align: center;
    color: $thColor;
    font-size: 16px;
    font-family: 'IRANSansMedium',sans-serif;
  }

}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  vertical-align: middle;
}
.remove-modal {
  width: 434px;
  button {
    margin: 0 10px;
    padding: 6px 25px !important;
  }
}

.avatar-load {
  width: 40px;
  background-image: url("../../image/user.svg");
  display: inline-block;
  background-repeat: no-repeat;
  margin-left: 15px;
  height: 40px;
  border-radius: 50px;
  vertical-align: middle;
}
.none{
  display: none;
}
.center-align {
  text-align: center;
}

.right-align {
  text-align: right;
}
.inline-block{
  display: inline-block;
}

.left-align {
  text-align: left;
}
.pointer{
  cursor: pointer;
}

.blur {
  filter: blur(4px);
}

.none {
  display: none;
}

.visible {
  visibility: visible;
}

.blur-height {
  margin-bottom: 200px;
}

.divider {
  height: 1px;
  width: 100%;
  background: $smokedColor;
}

a {
  text-decoration: none;
  color: $white;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}
.auto-margin {
  margin: auto;
}
.ltr {
  direction: ltr;
  text-align: left;
}
.panel-container {
  min-height: 600px;
  border-radius: 6px;
  margin:25px  auto 200px;    width: 90%;
  background: $white;
}
.padding-box {
  padding:20px;
}
