@import "../global/variable";
@import "./add";
@import "./permission";

.team-page {

  .remove-modal {
    width: 434px;

    button {
      margin: 0 10px;
      padding: 6px 25px !important;
    }

    p {
      margin-bottom: 25px;
    }
  }

  .icon-delete {
    position: relative;
    top: -14px;
  }

  td {
    .vs__clear {
      display: none;
    }
  }



  .vue-js-switch.disabled[data-v-25adc6c0] {
    opacity: 1 !important;
  }

  .inActive {
    .v-switch-core {
      background-color: $bgToggle !important;
    }

    .v-switch-button {
      background-color: #84818E !important;
    }
  }

  .active {
    .v-switch-core {
      background-color: #7367F0 !important;
    }
  }

  table {
    th {
      background-color: $smokedColor;
    }

    tr:nth-child(even) {
      background-color: $bgInActive;
    }

    .status-text {
      color: $thColor;
      font-size: $fontNormal;
      font-family: 'IRANSansMedium', sans-serif;
      display: inline-block;
      width: 46px;
    }
  }

  .first {
    text-align: left;
    margin-bottom: 20px;

    .blue {
      margin-right: 15px;
    }
  }

  .role-component {

      .left-col{
      border-right: 1px solid $darkGray;
    }
    form {
      display: flex;
    }

    .gray {
      display: inline-block;
      background: #A7AEB5;
      width: 38px;
      height: 38px;
      border-radius: 5px;
      vertical-align: middle;
      position: relative;
      top: 36px;
      border: none;
      .icon-add {
        &:before{
          margin-top: 7px;
          margin-right: 6px;
        }
      }
    }
    .role-list{
      width: 94%;padding: 11px 0;
    }
  .role-list:nth-child(even){
    background-color: #F9F9F9;

  }
    .validate-box {
      width: 100%;
      display: inline-block;
      margin-bottom: 14px;
    }
    .left{
      text-align: left;
      position: relative;
      left: 20px;
      .icon{
        margin-right: 42px;
      }
    }
    .right{
      text-align: right;
      span{
        color: #717171;
        font-size: 14px;
        font-family: 'IRANSansMedium', sans-serif;

      }
    }
  }

}