@import "./variable";

.vpd-input-group{
  input{
    padding: 7px 8px !important;;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px ;
    color: $label !important;;
    border: 1px solid $borderInput  !important;;
    font-family: 'IRANSansFaNum', sans-serif !important;
    border-right: none !important;
  }
  label {
    width: unset;
    margin: unset;
    background-color: $secondaryColor !important;
    border: 1px solid $borderInput;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;}
  }
.vpd-day-effect{
  background-color: $secondaryColor !important;
}
.vpd-body{
  font-family: 'IRANSansFaNum', sans-serif;
}
.vpd-header{
  font-family: 'IRANSansFaNum', sans-serif;
  background-color: $bodyBg !important;
  color: black !important;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.vpd-date{
  span{
    font-size: $fontNormal;
  }
}
.vpd-content {
  border-radius: 10px;
}
.vpd-actions{
  button{
    color: $thColor !important;
  }
}


