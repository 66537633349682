@import "./variable";

.icon-title {
  &:before {
    border-radius: 100px;
  }
}

.blue-bg {
  &:before {
    color: #2b4a83;
    padding: 7px;
    background: rgba(43, 74, 131, 0.2117647059);
  }
}

.red-bg {
  &:before {
    color: #E85354;
    background: #FEF8F8;
    font-size: 14px;
    padding: 9px;

  }
}

.green-bg {
  &:before {
    color: #00b894;
    background: rgba(0, 184, 148, .15);
    font-size: 14px;
    padding: 9px;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(38, 46, 62, 0.5);
  align-items: center;
  transition: opacity 0.3s ease;
  display: grid;
  overflow-y: auto;overflow-x: hidden;
}




.modal-container {
  //margin: 0 auto;
  //direction: rtl;
  //padding: 20px 48px;
  //background-color: #fff;
  //box-shadow: 0 4px 8px rgb(19 37 71 / 10%);
  //border-radius: 10px;
    width: 80%;
    max-width: 450px;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: white;
  color: rgb(14, 30, 37);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgb(14 30 37 / 16%);
  overflow-y: scroll;
  overflow-x: hidden;
  margin: auto;
}

.modal-header {
  text-align: left;

  .icon-close {
    display: inline-block;

    &:before {
      color: $darkgrey;
      font-size: 12px;
    }
  }

  .modal-title {
    display: inline-block;
    font-size: 18px;
    line-height: 28px;
    text-align: right;
    color: #000000;
    font-family: 'IRANYekanBold', sans-serif;
  }
}


.modal-body {
  margin: 20px 0;

  label {
    width: 100%;
    margin: 11px auto;
  }

  .contains {
    p {
      font-family: 'IRANSansMedium', sans-serif;
      font-size: 16px;
      line-height: 18px;
      color: $mainColor;
    }
  }
}

.modal-footer {
  text-align: left;
  margin-bottom: 8px;

  a:nth-child(1) {
    margin-left: 14px;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}




