@import "../../global/variable";

.edit-page {
  padding: 36px 33px;
  .vertical{
    position: relative;
    top: -28px;
  }
  .file{
    background: #F3F2F7;
    border-radius: 3px;
    margin-top: 26px;
    padding: 1px 7px;
    height: 36px;
    direction: ltr;
    display: inline-block;
    .name{
      color: #979797;font-size: 14px;    padding: 0 10px;
    }
  }
  .close-upload{
    display: inline;
    float: left;
    margin-top: 12px;
  }
  .error {
    background: $errorUpload !important;
    border-color: $errorUpload !important;
  }

  .type-error {
    color: $errorColor;
    font-size: 12px;
    display: block;
    position: relative;
    top: -7px;right: 38px;
  }

  .address {
    input[type=text] {
      width: 97%;
    }
  }

  .right {
    .action-btn {
      text-align: left;
      margin: 20px 0 0;
    }
  }

  .divider {
    margin: 40px 0;
  }

  .left {
    text-align: left;margin-bottom: 20px;

    input[type=file] {
      outline: none !important;
      color: transparent;
      opacity: 0;
      position: absolute;
      background: #F2F3F5;
      margin-right: -222px;
      margin-top: 6px;
    }

    .btn {
      padding: 4px 40px 4px 31px;
      width: max-content;
      border-radius: 6px;
      color: $white;
      outline: none;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      font-family: "IRANSansMedium", sans-serif;
      display: inline;

      &.gray-btn {
        border: 1px solid #A7AEB5;
        background: #A7AEB5;
      }
      .icon-upload-white {
        &:before {
          margin-right: -26px;
          margin-top: 7px;
        }
      }
      &.uploading {
        border: 1px solid $inProcessText;
        background: $inProcessText;
        .icon-load {
          &:before {
            margin-right: -33px;
            margin-top: 5px;
          }
        }
      }


    &.success {
      border: 1px solid $doneText;
      background: $doneText;

      .icon-close-upload {
        &:before {
          margin-right: -26px;
          margin-top: 11px;
        }
      }
    }

  }
}

.upload-img {
  div {
    background-image: url("../../../image/avatarAdd.svg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
  }
}

.upload-text {
  font-size: $fontNormal;
  color: $thColor;
  display: inline-block;
  font-family: "IRANSansMedium", sans-serif;
  margin: 10px 0;
}

}
