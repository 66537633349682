@import "../global/variable";

.vs__clear{
  margin: 0  8px;
  svg{
    transform: scale(0.8);

  }
}
.vs--open .vs__open-indicator {
  transform: rotate(
                  180deg
  ) scale(0.8);
}
.vs__dropdown-toggle{
  padding: 3px 8px;
  color: $crimson;
  border: 1px solid $borderInput;
  font-size: 14px;
  height: 37px;
}
.vs__open-indicator {
  transform: scale(0.6);
}
.vs__dropdown-option--highlight {
  background:#ffb3001c;
  color: $lightGray;
  font-size: 14px;
  padding: 8px 20px;
}
.vs__dropdown-option {
  color: #7B7B7B;
  font-size: 14px;
  padding: 8px 20px;
}
.vs__dropdown-menu{
  text-align: right;
}