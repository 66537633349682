@import "../global/variable";

/**------------------
 css for global-loading
-------------------- */
.loading-global {
  box-sizing: border-box;
  background-color: $white;
  z-index: 9;
  height: 1589px;

  .loading-full {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .logo {
      margin-bottom: 60px;

      .img {
        background: url("../../image/Logo.svg") no-repeat center center;
        background-size: cover;
        height: 40px;
        width: 400px;
        position: relative;
        right: 1px;
        margin: auto;
      }

    }

  }

}
.loading-data{
  background-color: rgba(255,255,255,0.36);
  height: 10px;
  .text{
    font-size: 14px;
    color: #2D3436;
    text-align: center;
    position: relative;
    top: 13px;
    font-family: IRANSansMedium !important;

  }
  .loading{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .loading-bro {
    width: 53px;
    background-color:$lightGray;
    padding: 12px 16px;
    border-radius: 18px;
    margin: auto;

    > h1 {
      text-align: center;
      font-size: 2.5em;
      margin-bottom: 1em;
      font-weight: 300;
      color: $gray;
    }
  }


  #load {
    margin:auto;
    animation: loading 3s linear infinite;
    position: relative;
    top: 4px;

    #loading-inner {
      stroke: {
        dashoffset: 0;
        dasharray: 300;
        width: 13px;
        miterlimit: 10;
        linecap: round;
      }
      animation: loading-circle 2s linear infinite;
      stroke: #fff;
      fill: transparent;
    }
  }

  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loading-circle {
    0% {
      stroke-dashoffset: 0
    }
    100% {
      stroke-dashoffset: -600;
    }
  }
}

/*  media (mobile, 576px) for gloabal-loading */
@media (max-width: 575px) {
  .loading-global {

    .logo {
      .img {
        width: 80px;
        height: 100px;
      }
    }
  }

}

