@import "../global/variable";
@import "./responsive";

.my-check-list-page {

  .padding-box{
    margin:0 auto;
  }
  .error {
    background: $errorUpload !important;
    border-color: $errorUpload !important;
    padding: 8px 15px 8px 50px !important;
    color: $white; .icon{
    margin-left: 30px;
  }

    .icon-upload-white{
      &:before{
        margin-top: 2px  !important;
        margin-right: unset !important;
    }

    }
  }

  .type-error {
    color: $errorColor;
    font-size: 12px;
    display: block;
    position: relative;
    top: -7px;
  }
  .btn {
    padding: 6px 14px;
    width: 100%;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-family: "IRANSansMedium", sans-serif;
    display: inline;
    .icon-upload-white{
      &:before {
        margin-right: -26px;
        margin-top: 7px;
      }
    }
    &.uploading {
      border: 1px solid $inProcessText;
      background: $inProcessText;
      padding: 8px 15px 8px 50px !important;
      color: $white;
      .icon{
        margin-left: 30px;
      }
      .icon-load {
        &:before {
          margin-right: -5px;
          margin-top: 0;
        }
      }
    }


    &.success {
      border: 1px solid $doneText;
      background: $doneText;
      padding: 8px 15px 8px 50px !important;
      color: $white;
      .icon{
        margin-left: 30px;
      }

      .icon-close-upload {
        &:before {
          margin-right: 0;
          margin-top: 6px;
        }
      }
    }

  }
  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .full-name{
    text-align: left;margin-top: 20px;
    span{
      display: unset !important;      border-radius: 100px;
      width: 80px;
      margin: auto;
      text-align: center;
      color: $crimson;
      font-size: 14px;
      font-family: "IRANSansMedium", sans-serif;
      padding-left: 40px;
    }
    .avatar{
      width: 22px;height: 22px;
    }

  }
  .m-b-60 {
    margin-bottom: 60px;
  }

  .left-row {


    .divider {
      margin: 30px 0;
    }

    .right-data {

      span {
        font-size:13px;

      }

      span:nth-child(2) {
        color: $crimson;
        padding: 0 4px;
      }

      span:nth-child(3) {
        color: $gray;
      }


    }

    .left-data {
      padding-top: 7px;

      span {
        color: $crimson;
        margin-right: 40px;
        font-size: $fontNormal;

      }

    }

    .detail-project {
      .title {
        font-size: $fontHeading;
        color: $blue;
      }

      .time {
        font-size: $fontLarge;
        color: $orange;
      }

      .description {
        color: $lightGray;
        line-height: 25px;
        text-align: justify;

      }
    }
  }

  .right-row {
    .user-info {

      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      padding:46px 0 10px 0;
      text-align: center;
      border-radius: 6px;
      margin-bottom: 40px;


      span {
        display: block;
      }
    }

    .user-name {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 100px;
      width: 80px;
      margin: auto;
      text-align: center;
      color: $red;
      font-size: 22px  !important;
      padding: 22px 0;
      font-family: 'IRANSansMedium', sans-serif;
    }

    .welcome {
      font-size: 22px;
      color: $white;
      font-family: 'IRANSansMedium', sans-serif;
      line-height: 42px;
    }

    .text {
      font-size: 14px;
      color: $black;
      width: 75%;
      text-align: justify;
      margin: auto;

    }

    .single-project {
      padding: 14px 20px;
      border: 1px solid;
      border-radius: 6px;
      margin: 15px 0;
      cursor: pointer;


      &.pending {
        border-color: $pendingText;
        background: $pendingBg;

        .right-align, .left-align {
          color: $pendingText;
        }

        .icon-status {
          &:before {
            background-image: url("../../image/icons/status/pending.svg");
          }
        }


        .arrow-down {
          &:before {
            background-image: url("../../image/icons/status/pending-arrow.svg");
          }
        }
      }

      &.done {
        border-color: $doneText;
        background: $transparent;

        .arrow-down {
          &:before {
            background-image: url("../../image/icons/status/done-arrow.svg");
          }
        }

        .icon-status {
          &:before {
            background-image: url("../../image/icons/status/done.svg");
          }
        }

        .right-align, .left-align {
          color: $doneText;
        }
      }

      &.in-process {
        border-color: $inProcessText;
        background: $transparent;

        .arrow-down {
          &:before {
            background-image: url("../../image/icons/status/inprocess-arrow.svg");
          }
        }

        .icon-status {
          &:before {
            background-image: url("../../image/icons/status/inProcess.svg");
          }
        }

        .right-align, .left-align {
          color: $inProcessText;
        }
      }

      .arrow-down {
        &:before {
          content: '';
          width: 18px;
          height: 18px;
          position: absolute;
          background-repeat: no-repeat;
          margin-top: 6px;
        }
      }

      .icon-status {
        &:before {
          content: '';
          width: 16px;
          height: 16px;
          position: absolute;
          background-repeat: no-repeat;
          margin-top: 6px;
          margin-right: 20px;
        }
      }

      .right-align {
        font-size: 18px;
        font-family: 'IRANSansMedium', sans-serif;
        position: relative;
        right: 57px;

      }

      .left-align {
        float: left;
        font-size: 16px;
      }


    }
  }

}
