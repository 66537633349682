@import "../global/variable";

.setting-page {
@media(max-width: 767px) {
  .action-btn{
    text-align: left;
    margin: -15px 0 27px 0
  }
    .department-info:nth-child(odd) {
      background: $bgInActive;
    }
  .department-info{
    padding: 5px 0 20px;
    input{
      top: 8px;
      position: relative;
    }
  }
  .xs{
    margin-top: 8px;
  }
  }
  @media (max-width: 991px) {


  }
  @media (min-width: 992px) and(max-width: 1200px) {
    .welcome{
      .show-welcome {
        margin: 50px auto;
      }
    }
    .setting-page{
        .create{
          margin: 10px 0;
        }
      }

  }
}
@media (min-width: 1200px)  {
  .welcome{
    .show-welcome {
      margin: 50px auto;
    }
  }
  .setting-page{
    .create{
      margin: 10px 118px;
    }
  }


}