@import "../global/variable";
@import "./tabResponsive";
.tabs-details {
  width: 100%;

}
.head {
  display: flex;
  overflow: hidden;
}
.tabs {
  width: max-content;
  .tab-item{
    width: max-content;
    display: inline-block;
    p{
      align-items: center;
      color: $lightGray;
      display: flex;
      justify-content: center;
      margin-bottom: -1px;
      padding: 0.5em ;
      vertical-align: top;
      font-size: $fontNormal;
      font-family: 'IRANSansMedium', sans-serif !important;
      cursor: pointer;
      text-decoration: none;
      transition: none 86ms ease-out;
    }
  }
  .-active {
    border-bottom: 3px solid $secondaryColor;
    color: $lightGray;
  }
  &.-dragging {
    opacity: 0;
  }
  ul {
    list-style: none;
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start;

    li {
      display: block;

      a {
        align-items: center;
        color: $lightGray;
        display: flex;
        justify-content: center;
        margin-bottom: -1px;
        padding: 0.5em ;
        vertical-align: top;
        font-size: $fontNormal;
        font-family: 'IRANSansMedium', sans-serif !important;
        cursor: pointer;
        text-decoration: none;
        transition: none 86ms ease-out;

      }

      &.is-active {
        a {
          border-bottom: 3px solid $secondaryColor;
          color: $lightGray;
        }

      }

    }
  }
}