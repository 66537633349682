@import "~flexboxgrid-sass/flexboxgrid";
@import "~vue-select/src/scss/vue-select.scss";

@import "./layout/index";
@import "./team/index";
@import "./setting/index";
@import "./project/index";
@import "./myCheckList/index";
@import "./auth/index";
@import "./global/index";


.D6lY4c {
  height: 22px;
  width: 22px;
  position: absolute;
  border-radius: 11px;
  top: -1px;
}
.xTFaxe {
  fill: #70757a;
  top: 2px;
}.IjabWd {
   margin-left: 2px;
 }









