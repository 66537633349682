@import "../../global/variable";

.single-Task {

  .pending{
    background: $pendingText;
  }
  .done{
    background-color: $doneText;
  }
  .status{
    text-align: center; color: $white;   width: 78px;

  }
  .in-process{
    background: $inProcessText;
  }
  .icon-box {
    text-align: left;

    .icon {
      margin: 0 22px;
    }

    .icon-up,.icon-down {
      position: relative;
      top: 5px;
    }
  }

  .open {
    td {
      border-top: 2px solid #F4F4F4;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
      border-bottom: 2px solid $white;
      color: #A7AEB5;
      white-space: normal !important;
      line-height: 28px;
      padding-bottom: 7px;
      padding-right: 10px;

    }

  }

  .simple-row {
    td {
      border-bottom: 2px solid $white;
    }
  }

  td {
    background: #F4F4F4;
    height: 30px;
    padding: 0 5px;
    font-size: $fontSmall;
  }

  .title-task {
    color: $textTd;
    padding-right: 10px;
    &:before{
      content: '';
      background-color: #7D84EB;
      width: 4px;
      height: 30px;
      position: absolute;
      margin-right: -10px;
      margin-top: -5px;
    }
  }
}
.single-Project{
  .percent{
    display: inline-block;    min-width: 300px;
  }
  .icon-more{
       position: relative;
       top: 12px;
    right: 20px;
     }

}
